import { Component, ViewChild, ElementRef, ViewChildren, HostListener } from '@angular/core';
import { NgxPageScrollDirective } from 'ngx-page-scroll';
import { pageScrollOffset } from './routing.module'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 title = 'indato GmbH';
  navCollapsed:boolean = true;
  private readonly HEADER_OFFSET: number = 40;
  @ViewChild('header') private header: ElementRef;
  @ViewChild('navbar') private navbar: ElementRef;
//  @ViewChild('navbarButton') private navbarButton: ElementRef;
  @ViewChildren(NgxPageScrollDirective) navigationElements;
     constructor(){}
   
   //fix navigation on top
   //@HostListener('window:scroll', ['$event']) // for window scroll events
   //public onScroll(event) {
    //    for(let cssClass of ['navbar-fixed-top', 'indato-animated', 'slideInDown']){
   //         if (event.currentTarget.scrollY > this.HEADER_OFFSET){
    //            this.header.nativeElement.classList.add(cssClass);
    //        } else {
    //            this.header.nativeElement.classList.remove(cssClass);
   //         }
   //     }
   // }
     
    public navBarClicked() {
       if (!this.navCollapsed) { // only for burger menu
           //close opened navbar on click in burger-toggle's navigation item
            this.navbar.nativeElement.classList.remove('in');
            this.navCollapsed = true;
        }
    }
    
    public setPageScrollOffset(){
        return pageScrollOffset;
    }
}

import { NgxPageScrollDirective } from "ngx-page-scroll";
import { Directive, Inject, OnChanges, OnDestroy, SimpleChanges, HostListener, ElementRef, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { PageScrollService } from "ngx-page-scroll-core";
import { DOCUMENT } from '@angular/common';

import { pageScrollOffset } from '../routing.module'


@Directive({
  selector: '[pageScroll]'
})
export class IndatoPageScrollDirective extends NgxPageScrollDirective implements AfterViewInit {
    private cPageScrollService: PageScrollService;
    private cRouter: Router;
    private cDocument: Document;

    constructor(private el: ElementRef, pageScrollService: PageScrollService, router: Router, @Inject(DOCUMENT) document: any) {
        super(pageScrollService, router, document);
        this.cPageScrollService = pageScrollService;
        this.cRouter = router;
        this.cDocument = document;
    }
    
    ngAfterViewInit(): void {
        this.onScroll(null);
    }
    

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
        //for activation navigation items in fixed nav (underlining)
        let element: HTMLElement = this.cDocument.querySelector<HTMLElement>(this.href);
        let sx: Number = event?event.currentTarget.scrollY:0;
        let islowestsx: boolean = event?(sx >= (event.target.scrollingElement.scrollHeight - event.target.scrollingElement.clientHeight)):false;
        if(element.offsetTop - pageScrollOffset - 1 <= sx && sx < (element.offsetTop + element.offsetHeight - pageScrollOffset - 1) && !islowestsx){
            this.el.nativeElement.parentNode.classList.add('active');
        } else if (islowestsx){
            this.el.nativeElement.parentNode.classList.remove('active');
            this.el.nativeElement.parentNode.parentNode.lastElementChild.classList.add('active');
        } else {
            this.el.nativeElement.parentNode.classList.remove('active');
        }
    }

}
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
// routes are no longer relevant, because of OnePage Design of elate
//    {path: 'home', component: HomeComponent},
//    {path: 'work', component: ProjectComponent},
//    {path: 'about', component: CompanyComponent},
//    {path: 'contact', component: ContactComponent},
];

const isBrowser = () => typeof window !== "undefined"
export const pageScrollOffset = (isBrowser() && window.matchMedia('(max-width: 768px)').matches)?64:0; 
//export const useHashValue = (isBrowser() && window.matchMedia('(max-width: 768px)').matches)?false:true;

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, pageScrollOffset],
//      onSameUrlNavigation: 'reload',
      useHash: true
    })],
    exports: [RouterModule]
})
export class RoutingModule {
}

<div id="indato-company">
    
<section  #section id="fh5co-about" class="animated">
    <div class="container">
            <div class="row">
                <div class="col-md-12 indato-section-heading text-center">
                    <h2 class="to-animate fadeInUp animated">{{'nav_company' | translation}}</h2>
                    <div class="row">
                        <div class="col-md-8 col-md-offset-2 subtext to-animate fadeInUp animated">
                                <h3>{{'text_company' | translation}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-content-md-center">
                <div class="col-md-2"></div>
                <div class="col-md-8">
                    <div class="fh5co-person text-center to-animate fadeInUp animated">
                        <figure>
                            <img src="assets/images/Thomas_Oexl.jpg" alt="Thomas_Oexl.jpg">
                        </figure>
                        <h3>Thomas Oexl</h3>
                        <span class="fh5co-position">Geschäftsführer</span>
                        <p>{{'text_thomas_oexl' | translation}}</p>
                        <ul style="list-style: none;">
                            <li><i class="icon-phone"></i> (+41) 78 723 64 80</li>
                            <li><i class="icon-envelope"></i><a href="mailto:thomas.oexl@indato.ch">&nbsp;thomas.oexl@indato.ch</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-2"></div>
            </div>
     </div>
</section>
    
</div>    